<template>
<transition name="fade">
	<router-view />
</transition>
</template>

<script>
export default {
	name: 'submissions'
}
</script>