<template>
<div class="page page-lg pt-0 position-relative">
	<transition name="fade">
		<div  v-if="!$apollo.loading"
					class="d-flex align-items-start">
			<div class="sidebar-nav overflow-visible sticky content-height-with-scroll">
				<button	class="btn btn-transparent back-btn mb-3"
								@click="on_back()">
					<i class="fas fa-chevron-left mr-2" />กลับไป
				</button>
				<scrollactive active-class="scrollactive-active"
											:offset="150"
											:modify-url="false"
											highlight-first-item>
					<a  href="#info"
							class="btn btn-transparent btn-block btn-lg scrollactive-item">
						<i class="fas fa-file-alt btn-inner-icon-lg" />
						ข้อมูลเบื้องต้น
					</a>
					<a  href="#reports"
							class="btn btn-transparent btn-block btn-lg scrollactive-item">
						<i class="fas fa-file-invoice btn-inner-icon-lg" />
						รายงาน
					</a>
					<a  href="#payment"
							class="btn btn-transparent btn-block btn-lg scrollactive-item">
						<i class="fas fa-file-invoice-dollar btn-inner-icon-lg" />
						การชำระเงิน
					</a>
					<a  v-if="submission.submission_type === SubmissionType.GENERAL"
							href="#sample-details"
							class="btn btn-transparent btn-block btn-lg scrollactive-item">
						<i class="fas fa-vial btn-inner-icon-lg" />
						ข้อมูลตัวอย่าง
					</a>
					<a  href="#test-details"
							class="btn btn-transparent btn-block btn-lg scrollactive-item">
						<i class="fas fa-microscope btn-inner-icon-lg" />
						การทดสอบ
					</a>
					<template v-if="auth.is_admin">
						<h4 class="text-medium border-t pt-4 pb-2">แอดมิน</h4>
						<router-link  :to="{
														name: 'edit-submission',
														params: { id: submission_key }
													}"
													tag="a"
													class="btn btn-transparent btn-block btn-lg"
													:disabled="!submission.BestLIMS_key">
							<i class="fas fa-edit btn-inner-icon-lg" />
							แก้ไขข้อมูล         
						</router-link>
					</template>
				</scrollactive>
			</div>
			
			<div class="sidebar-nav-content pt-5 mt-5">
				<div  id="info"
							class="section">
					<h3 class="mb-5 mt-2 font-cu">
						<i class="fas fa-file-alt icon-lg" />
						การส่งตัวอย่างหมายเลข #{{ $route.params.id }}
					</h3>
		
					<div class="row mb-4">
						<div class="col-2">
							<h4>สถานะ</h4>
						</div>
						<div class="col">
							<div class="form-row">
								<template v-if="submission.submission_status === SubmissionStatus.AWAIT_SAMPLE">
									<div class="form-group col-3">
										<ColorTag
											class="d-block font-cu"
											:color="COLORS.SUBMISSION_STATUS[submission.submission_status]"
											:label="submission.submission_status"
											size="lg" />
									</div>
									<FormInput
										class="col-3"
										disabled
										:value="`วันที่ส่ง: ${to_display_date(submission.submit_date)}`" />
								</template>
								<template v-else>
									<div class="form-group col-6 mb-0">
										<div class="form-row w-100 m-0">
											<div class="form-group col pl-0 mb-0">
												<FormInput
													class="font-cu mb-3"
													disabled
													:value="SubmissionStatus.AWAIT_SAMPLE" />
												<FormInput
													disabled
													:value="`วันที่ส่ง: ${to_display_date(submission.submit_date)}`" />
											</div>
											<div class="form-group col-auto mb-0">
												<i class="fas fa-arrow-right icon-md text-center mt-2" />
											</div>
											<div 	v-if="submission.submission_status !== SubmissionStatus.CANCELLED"
														class="form-group col pr-0 mb-0">
												<ColorTag
													v-if="submission.submission_status === SubmissionStatus.IN_PROGRESS"
													class="d-block font-cu form-group mb-3"
													:color="COLORS.SUBMISSION_STATUS[submission.submission_status]"
													:label="submission.submission_status"
													size="lg" />
												<FormInput
													v-else-if="submission.submission_status === SubmissionStatus.COMPLETED"
													class="font-cu mb-3"
													disabled
													:value="SubmissionStatus.IN_PROGRESS" />
												<FormInput
													disabled
													:value="`วันที่รับ: ${to_display_date(submission.sample_received_date)}`" />
											</div>
											<div 	v-else
														class="form-group col pr-0">
												<ColorTag
													class="d-block font-cu form-group mb-3"
													:color="COLORS.SUBMISSION_STATUS[submission.submission_status]"
													:label="submission.submission_status"
													size="lg" />
												<FormInput
													disabled
													:value="`วันที่: ${to_display_date(submission.cancel_date)}`" />
											</div>
										</div>
									</div>
									<div  v-if="submission.submission_status === SubmissionStatus.COMPLETED"
												class="form-group col-3 p-0 mb-0">
										<div class="form-row w-100 m-0">
											<div class="form-group col-auto mb-0">
												<i class="fas fa-arrow-right icon-md text-center mt-2" />
											</div>
											<div class="form-group col pr-0 mb-0">
												<ColorTag
													class="d-block font-cu form-group mb-3"
													:color="COLORS.SUBMISSION_STATUS[submission.submission_status]"
													:label="submission.submission_status"
													size="lg" />
												<FormInput
													disabled
													:value="`วันที่: ${to_display_date(submission.completed_date)}`" />
											</div>
										</div>
									</div>
								</template>
							</div>
						</div>
					</div>

					<div class="row mb-4">
						<div class="col-2">
							<h4>เอกสาร</h4>
						</div>
						<div class="col">
							<div class="form-row">
								<DownloadSubmissionForm
									v-if="submission && submission.BestLIMS_key"
									class="col-6"
									btn-class="btn-secondary text-left"
									iframe_key="iframe-view-submission"
									:submission="submission" />
								<div class="w-100"></div>
								<DownloadSlip
									v-if="submission.submission_status === SubmissionStatus.AWAIT_SAMPLE"
									class="col-6"
									:submission-key="submission_key" />
							</div>
						</div>
					</div>
		
					<div class="row mb-4">
						<div class="col-2">
							<h4>ข้อมูลเบื้องต้น</h4>
						</div>
						<div class="col">
							<div class="form-row mb-3">
								<div class="form-group col-6">
									<label>ประเภทการทดสอบ</label>
									<ColorTag
										class="d-block font-cu"
										size="lg"
										:color="COLORS.SUBMISSION_TYPE[submission.submission_type]"
										:label="submission.submission_type" />
								</div>
							</div>
							<div class="form-row">
								<div class="col-6">
									<div class="form-row">
										<template v-if="submission.thai_report">
											<FormInput
												class="col-12 mb-2"
												label="ผู้ส่งตัวอย่าง"
												disabled
												:value="submission.submitter.name" />
											<FormTextarea
												class="col-12 mb-2"
												rows="3"
												disabled
												:value="submission.submitter.address" />
										</template>
										<template v-if="submission.english_report">
											<FormInput
												class="col-12 my-2"
												label="ผู้ส่งตัวอย่าง (English)"
												disabled
												:value="submission.submitter.name_en" />
											<FormTextarea
												class="col-12 mb-2"
												rows="3"
												disabled
												:value="submission.submitter.address_en" />
										</template>
										<FormTextarea
											class="col-12 mt-2"
											rows="2"
											disabled
											:value="to_contact_details(submission.submitter)" />
									</div>
								</div>
								<div class="col-6 pl-3">
									<div class="form-row">
										<div class="form-row">
											<template v-if="submission.thai_report">
												<FormInput
													class="col-12 mb-2"
													label="เจ้าของตัวอย่าง/ฟาร์ม"
													disabled
													:value="submission.sample_owner.name" />
												<FormTextarea
													class="col-12 mb-2"
													rows="3"
													disabled
													:value="submission.sample_owner.address" />
											</template>
											<template v-if="submission.english_report">
												<FormInput
													class="col-12 my-2"
													label="เจ้าของตัวอย่าง/ฟาร์ม (English)"
													disabled
													:value="submission.sample_owner.name_en" />
												<FormTextarea
													class="col-12 mb-2"
													rows="3"
													disabled
													:value="submission.sample_owner.address_en" />
											</template>
											<FormTextarea
												class="col-12 mt-2"
												rows="2"
												disabled
												:value="to_contact_details(submission.sample_owner)" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div v-if="submission.remarks" class="row mb-4">
						<div class="col-2">
							<h4>หมายเหตุอื่นๆ</h4>
						</div>
						<div class="col">
							<div class="form-row">
								<div class="col-12">
									<div class="form-row">
										<FormTextarea
											class="col-12"
											disabled
											:value="submission.remarks" />
									</div>
								</div>
							</div>
							
						</div>
					</div>

					<div v-if="auth.is_admin" class="row mb-4">
						<div class="col-2">
							<h4 class="mb-1">ข้อมูลทางระบบ</h4>
							<h4 class="text-muted">[ สำหรับแอดมิน ]</h4>
						</div>
						<div class="col">
							<div class="form-row">
								<FormInput
									class="col-3"
									label="หมายเลขใน BestLIMS"
									disabled
									:value="submission.BestLIMS_key" />
								<FormInput
									class="col-3"
									label="หมายเลขใน Web Server"
									disabled
									:value="submission.backend_key" />
								<div class="w-100"></div>
								<FormDateInput
									class="col-3"
									label="วันที่แก้ไขครั้งล่าสุด"
									format="dd/MM/YY"
									disabled
									:value="submission.last_edit_date" />
								<FormDateInput
									class="col-3"
									label="วันที่ Sync กับ BestLIMS ล่าสุด"
									format="dd/MM/YY"
									disabled
									:value="submission.BestLIMS_last_contact_time" />
								<div class="w-100"></div>
								<TickView
									class="col-6 mt-2"
									label="ข้อมูลล่าสุดถูก Sync ไปที่ BestLIMS"
									:value="submission.synced_to_BestLIMS" />
							</div>
							
						</div>
					</div>
				</div>
		
				<div id="reports"
							class="section">
					<h3 class="mb-4 font-cu">
						<i class="fas fa-file-invoice icon-lg" />
						รายงาน
					</h3>
					<div class="row mb-3">
						<div class="col-12">
							<table class="mb-4">
								<thead>
									<tr>
										<th>หมายเลขรายงาน</th>
										<th class="text-right">Version</th>
										<th>วันที่ออก/แก้ไขล่าสุด</th>
										<th>รายละเอียด</th>
										<th>ไฟล์รายงาน (PDF)</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(report, idx) in submission.reports"
											:key="idx"
											:class="{ highlight: $route.query.report === report.report_no }">
										<td>{{ report.report_no }}</td>
										<td class="text-right">{{ report.report_version }}</td>
										<td>{{ to_display_date(report.report_date) }}</td>
										<td class="small-font pre-line">{{ report.report_description || '--' }}</td>
										<td>
											<div class="form-row">
												<FileView
													class="col-12 mb-0"
													btn-class="btn-secondary btn-sm"
													:file="report.report_file"
													icon-class="fas fa-file-invoice" />
											</div>
										</td>
									</tr>
									<tr v-if="submission.reports.length <= 0">
										<td class="text-center" colspan="5">
											<h5 class="text-muted font-cu py-2">
												<i class="far fa-frown mr-2"></i>ยังไม่มีรายงาน
											</h5>
										</td>
									</tr>
								</tbody>
							</table>
								
							<!-- <div  v-if="submission.reports.length > 0"
										class="row mt-3">
								<div class="col-7"></div>
								<div class="col">
									<button class="btn btn-primary btn-block font-cu"
													@click="download_all_reports()">
										<i class="fas fa-file-archive btn-inner-icon"></i>
										ดาวน์โหลดทั้งหมด (เป็น zip)
									</button>
								</div>  
							</div> -->
						</div>

					</div>

					<div class="row mb-3">
						<div class="col-2">
							<h5>ภาษารายงาน</h5>
						</div>
						<div class="col">
							<div class="form-row">
								<TickView
									class="col-6"
									label="รายงานภาษาไทย"
									:value="submission.thai_report" />
								<div class="w-100"></div>
								<TickView
									class="col-6"
									label="รายงานภาษาอังกฤษ (English Report)"
									:value="submission.english_report" />
							</div>
						</div>
					</div>

					<div class="row mb-3">
						<div class="col-2">
							<h5>ส่งรายงานทางไปรษณีย์</h5>
						</div>
						<div class="col">
							<div class="form-row">
								<TickView class="col-6"
											label="ส่งรายงานให้ ผู้ส่งตัวอย่าง ทางไปรษณีย์"
											:value="submission.mail_report_to_submitter" />
								<div class="w-100"></div>
								<TickView v-if="submission.submitter.index !== submission.sample_owner.index"
													class="col-6"
													label="ส่งรายงานให้ เจ้าของตัวอย่าง/ฟาร์ม ทางไปรษณีย์"
													:value="submission.mail_report_to_sample_owner" />
							</div>
						</div>
					</div>

				</div>
		
				<div  id="payment"
							class="section">
					<h3 class="mb-4 font-cu">
						<i class="fas fa-file-invoice-dollar icon-lg" />
						การชำระเงิน
					</h3>
		
					<div class="row">
						<div class="col-2">
							<h4>สถานะ Invoice</h4>
						</div>
						<div class="col">
							<div class="form-row">
								<div class="form-group col-6">
									<ColorTag
										class="d-block font-cu"
										size="lg"
										:color="submission.invoice? COLORS.INVOICE_STATUS[submission.invoice.invoice_status] : 'grey'"
										:label="submission.invoice? submission.invoice.invoice_status : 'ยังไม่ Invoice'" />
								</div>
							</div>
						</div>
					</div>
	
					<div  v-if="submission.invoice"
								class="row">
						<div class="col-2">
							<h4>หมายเลข Invoice</h4>
						</div>
						<div class="col">
							<div class="form-row">
								<FormInput
									class="col-6"
									disabled
									:value="submission.invoice.invoice_no" />
							</div>
						</div>
					</div>
		
					<div class="row mb-4">
						<div class="col-2">
							<h4>ชื่อบน Invoice</h4>
						</div>
						<div class="col">
							<div class="form-row">
								<FormInput
									class="col-6 mb-2"
									disabled
									:value="submission.invoice_to.name" />
								<div class="w-100"></div>
								<FormTextarea
									class="col-6 mb-2"
									rows="3"
									disabled
									:value="submission.invoice_to.address" />
								<div class="w-100"></div>
								<FormTextarea
									class="col-6"
									rows="2"
									disabled
									:value="to_contact_details(submission.invoice_to)" />
							</div>
						</div>
					</div>

					<div v-if="submission.invoice" class="row mb-4">
						<div class="col-2"></div>
						<div class="col">
							<div class="form-row">
								<div class="form-group col-6 text-right">
									<router-link  :to="{
																	name: auth.is_admin? 'admin-invoice-list' : 'invoice-list',
																	params: { id: submission.invoice.invoice_no }
																}"
																tag="button"
																class="btn btn-secondary btn-block font-cu">
										<i class="fas fa-arrow-right btn-inner-icon"></i> ไปดูรายละเอียด Invoice
									</router-link>
								</div>
							</div>
						</div>
					</div>
		
				</div>
				<div  v-if="is_general"
							id="sample-details"
							class="section">
					<h3 class="mb-4 font-cu">
						<i class="fas fa-vial icon-lg"></i> ข้อมูลตัวอย่าง
					</h3>
					<div class="row">
						<div class="col-12">
							<div class="form-row">
								<FormInput
									class="col-4"
									label="ประเภทตัวอย่าง"
									disabled
									:value="submission.sample_details.sample_type || '--'" />
								<FormDateInput
									class="col-2"
									label="วันที่เก็บตัวอย่าง"
									format="dd/MM/yy"
									disabled
									:value="submission.sample_details.sample_taken_date" />
								<div class="w-100"></div>
								<FormInput
									class="col-4"
									label="ชนิดสัตว์"
									disabled
									:value="submission.sample_details.animal_type || '--'" />
								<FormInput
									class="col-4"
									type="text"
									label="พันธุ์"
									disabled
									:value="submission.sample_details.animal_species || '--'" />
								<FormInput
									class="col-2"
									type="text"
									label="อายุสัตว์"
									disabled
									:value="submission.sample_details.animal_age || '--'" />
								<FormInput
									class="col-2"
									label="จำนวนที่เลี้ยง"
									disabled
									:value="submission.sample_details.animal_count || '--'" />
								<FormTextarea 
									class="col-6"
									type="text"
									label="ประวัติการป่วย"
									rows="3"
									disabled
									:value="submission.sample_details.illness || '--'" />
								<FormTextarea
									class="col-6"
									type="text"
									label="ประวัติการทำวัคซีน"
									rows="3"
									disabled
									:value="submission.sample_details.vaccinations || '--'" />
							</div>
						</div>
					</div>
				</div>
		
				<div id="test-details" class="section">
					<h3 class="mb-4 font-cu">
						<i class="fas fa-microscope icon-lg"></i>
						รายละเอียดการทดสอบ
					</h3>
					<div v-if="submission.submission_batches" class="row">
						<div class="col-12 px-4">
							<div  v-for="(batch, idx) of submission.submission_batches"
										:key="idx"
										class="mb-3 pb-5 d-flex"
										:class="{'border-b': multiple_batches}">

								<template v-if="is_general">
									<div v-if="multiple_batches" class="batch-sidebar general pt-3 px-2">
										<h3 class="mb-3">กลุ่ม {{ idx+1 }}</h3>
										<h4 class="text-medium">{{ batch.sample_count }} ตัวอย่าง</h4>
									</div>
									<div class="w-100">
										<template v-for="(test_department, department) in batch.tests">
											<div  v-if="test_department"
														:key="department"
														class="mb-4">
												<div class="row no-gutters py-3">
													<div class="col-12">
														<ColorTag
															class="d-block font-cu"
															size="lg"
															:color="COLORS.GENERAL_TEST_DEPARTMENT[department]"
															:label="`งาน${department}`" />
													</div>
												</div>
												<div class="row test-row row-header py-2">
													<div class="col-2 nowrap">
														<h5>กลุ่ม</h5>
													</div>
													<div class="col">
														<div class="row">
															<div class="col-7">
																<h5>รายการทดสอบ</h5>
															</div>
															<div class="col-2 text-right">
																<h5>ราคา/ตัวอย่าง</h5>
															</div>
															<div class="col-1"></div>
															<div class="col-2 text-right">
																<h5>ยอดค่าบริการ</h5>
															</div>
														</div>
													</div>
												</div>
							
												<div  v-for="(tests, category) in test_department.test_list"
															:key="category"
															class="row test-row border-b pb-1">
													<div class="col-2 text-dark">
														<h5 class="text-dark py-1">{{ category }}</h5>
													</div>
													<div v-if="tests" class="col">
														<div  v-for="test of tests"
																	:key="test.test_key"
																	class="row test-row py-1">
															<div class="col-7">
																<h5>{{ test.display_name }}</h5>
															</div>
															<div class="text-right col-2">
																<h5>{{ to_display_price(test.price) }}</h5>
															</div>
															<div class="col-1 text-left text-muted nowrap">
																<i class="fas fa-times icon-sm d-inline"></i>
																<h5 class="mx-1 d-inline">{{ batch.sample_count }}</h5>
																<i class="fas fa-equals icon-sm d-inline"></i>
															</div>
															<div class="col-2 text-right">
																<h5>
																	{{ to_display_price(test.price * batch.sample_count) }}
																</h5>
															</div>
														</div>
													</div>
												</div>
						
												<div  v-if="test_department.custom_bacteria_tests && test_department.custom_bacteria_tests.length > 0"
															class="row test-row border-b">
													<div class="col-2 text-dark">
														<h5 class="text-medium py-1">แบคทีเรียอื่นๆ</h5>
													</div>
													<div class="col">
														<div  v-for="(custom_bacteria, idx) of test_department.custom_bacteria_tests"
																	:key="idx"
																	class="row test-row py-1">
															<div class="col-7">
																<h5>{{ custom_bacteria }}</h5>
															</div>
															<div class="col-5 text-right">
																<h5>~{{ to_display_price(500 * batch.sample_count) }}</h5>
															</div>
														</div>
													</div>
												</div>
												<div  v-if="test_department.sensitivity_tests"
															class="row test-row border-b">
													<div class="col-2 text-dark py-1">
														<h5 class="text-medium">ยาต้านจุลชีพเพื่อการทดสอบความไว</h5>
													</div>
													<div class="col py-1">
														<div class="row test-row">
															<div class="col-8 py-1">
																<h5 v-for="antibiotic of test_department.sensitivity_tests.split(',')"
																		:key="antibiotic">
																	{{ antibiotic }}
																</h5>
															</div>
														</div>
													</div>
												</div>
											</div> 
										</template>

										<div class="row test-row py-3 border-b">
											<div class="col-2"></div>
											<div class="col">
												<div class="row test-row">
													<div class="col"></div>
													<div class="col-2 text-right">
														<h2 class="text-primary">
															{{ batch.sample_count }}
														</h2>
														<h5 class="text-medium">ตัวอย่าง</h5>
													</div>
													<div class="col-3 text-right">
														<h2 class="text-primary">
															{{ batch.test_count }}
														</h2>
														<h5 class="text-medium">รายการทดสอบ</h5>
													</div>
													<div class="col-1 text-right nowrap">
														<h5 class="text-medium mt-2 ml-3">รวมเป็น</h5>
													</div>
													<div class="col-3 text-right">
														<h2 class="text-primary">
															{{ to_display_price(batch.price) }}
														</h2>
														<h5 class="text-medium">ค่าบริการ</h5>
													</div>
												</div>
											</div>
										</div>

										<div class="row mb-2 mt-5 no-gutters">
											<div class="col-12">
												<h4>รายละเอียดตัวอย่าง</h4>
											</div>
										</div>
										<div class="row row-header py-2">
											<div class="col-4">
												<h5>ID ตัวอย่าง</h5>
											</div>
											<div class="col">
												<h5>ข้อมูลเพิ่มเติม</h5>
											</div>
										</div>
										<div  v-for="(sample, idx) of batch.samples"
													:key="idx"
													class="row py-1 border-b">
											<div class="col-4">
												<h5>{{ sample.sample_id }}</h5>
											</div>
											<div class="col">
												<h5>{{ sample.extra_info? sample.extra_info : '--' }}</h5>
											</div>
										</div>
									</div>
								</template>

								<template v-else-if="is_disinfectant">
									<div v-if="multiple_batches" class="batch-sidebar pt-3 pl-2 pr-4 disinfectant">
										<h5 class="text-medium">ยาฆ่าเชื้อ</h5>
										<h4>{{ batch.disinfectant_name }}</h4>
									</div>
									<div class="w-100">
										<div class="form-row py-3">
											<FormInput
												v-if="!multiple_batches"
												class="col-6 px-0"
												label="ชื่อยาฆ่าเชื้อ"
												disabled
												:value="batch.disinfectant_name" />
											<div class="w-100"></div>  
											<div class="col-6 form-group px-0">
												<label>ทดสอบประสิทธิภาพต่อ</label>
												<ColorTag
													class="d-block font-cu"
													size="lg"
													:color="COLORS.DISINFECTANT_TEST_TYPE[batch.test_type]"
													:label="batch.test_type" />
											</div> 
										</div>
										<div class="form-row row-header px-3 py-2">
											<div class="col-4">
												<h5>
													ชื่อ{{ batch.test_type === DisinfectantTestType.VIRUS? DisinfectantTestType.VIRUS : DisinfectantTestType.BACTERIA }}
												</h5>
											</div>
											<div class="col-6">
												<div class="form-row">
													<div class="col-4">
														<h5>ความเข้มข้น</h5>
													</div>
													<div class="col-8">
														<div class="form-row">
															<div v-if="batch.test_type === DisinfectantTestType.BACTERIA_CP" class="col-6">
																<h6>ระยะหลังการเจือจาง</h6>
															</div>
															<div class="col-6">
																<h5>ระยะสัมผัสเชื้อ</h5>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div  v-for="(test_detail, test_name) in batch.tests"
													:key="test_name"
													class="form-row test-row border-b px-3 py-1">
											<div class="col-4 pr-5">
												<h4 class="mb-1">{{ test_detail.display_name }}</h4>
												<h5 v-if="batch.test_type === DisinfectantTestType.VIRUS"
														class="text-muted">
													[ {{ test_detail.cell_name }} ]
												</h5>
											</div>
	
											<div class="col-6">
												<div  v-for="dilution of test_detail.dilutions"
															:key="dilution"
															class="form-row test-row">
													<div class="col-4 pl-3 position-relative">
														<h4 class="d-inline">{{ dilution }}</h4>
													</div>
	
													<div  v-if="batch.test_type !== DisinfectantTestType.BACTERIA_CP"
																class="col-4">
														<div  v-for="time of test_detail.contact_times"
																	:key="time"
																	class="test-row pl-2 position-relative">
															<h5 class="d-inline">{{ time }}</h5>
														</div>
													</div>
	
													<div v-else class="col-8">
														<div  v-for="dt of test_detail.dilution_times"
																	:key="dt"
																	class="form-row test-row pl-2">
															<div class="col-6">
																	<h5 class="d-inline">{{ dt }}</h5>
															</div>
															<div class="col-6">
																<div  v-for="time of test_detail.contact_times"
																			:key="time"
																			class="form-row test-row pl-1">
																	<div class="col-12">
																		<h5 class="d-inline">{{ time }}</h5>
																	</div>
																</div>
															</div>
														</div>
													</div>
	
												</div>
											</div>
	
											<div class="col-2 d-flex flex-column align-items-end justify-content-end pr-4">
												<h5>3,000฿</h5>
												<h6 class="text-medium squeeze-up">ต่อรายการ</h6>
												<h5 class="mt-1">
													<i class="fas fa-times icon-sm"></i>
													{{ test_detail.test_count }}
												</h5>
												<h6 class="text-medium squeeze-up">รายการ</h6>
												<h4 class="mt-1" :class="`text-${COLORS.DISINFECTANT_TEST_TYPE[batch.test_type]}`">
													<i class="fas fa-equals mr-1 icon-sm"></i>
													{{ to_display_price(test_detail.price) }}
												</h4>
											</div>
										</div>

										<div class="form-row p-3 border-b">
											<div class="col d-flex justify-content-end align-items-end">
												<div  v-if="batch.test_type === DisinfectantTestType.VIRUS"
															class="text-right position-relative cost-container">
													<h4 class="text-yellow">
														<i class="fas fa-plus icon-sm"></i>
														{{ to_display_price(batch.toxicity_test_cost) }}
													</h4>
													<h5 class="text-medium">ค่า Toxicity Test</h5>
													<div class="hint-box">
														<h4 class="text-primary mb-1">การคำนวณค่า Toxicity Test</h4>
														<h5>คำนวณจากจำนวนความเข้มข้นของแต่ละประเภท cell ไวรัส ซึ่งระบุไว้ใน [ ] หลังชื่อไวรัส หากไวรัสหลายตัวภายใต้ cell ประเภทเดียวกันใช้ความเข้มข้นซํ้ากัน จะคิดราคาแค่รอบเดียวเท่านั้น</h5>
														<div  v-for="(dilutions, cell) in batch.unique_cells"
																	:key="cell">
															<h5 class="text-yellow">{{ cell }}</h5>
															<h5 class="ml-2">
																3,000฿
																<i class="fas fa-times icon-sm" />
																{{ dilutions.length}} ความเข้มข้น
																<i class="fas fa-equals icon-sm" />
																<span class="text-yellow ml-1">{{ to_display_price(3000 * dilutions.length) }}</span>
															</h5>
														</div>
													</div>
												</div>
												<div class="text-right ml-4">
													<h4 :class="`text-${COLORS.DISINFECTANT_TEST_TYPE[batch.test_type]}`">
														<i class="fas fa-plus icon-sm"></i>
														3,000฿
													</h4>
													<h5 class="text-medium">ค่าประเมินผล</h5>
												</div>
											</div>
											<div class="col-1">
												<h5 class="text-medium mt-2">รวมเป็น</h5>
											</div>
											<div class="col-2 pr-4 d-flex flex-column align-items-end justify-content-end">
												<h2 :class="`text-${COLORS.DISINFECTANT_TEST_TYPE[batch.test_type]}`">
													{{ to_display_price(batch.price) }}
												</h2>
												<h5 class="text-medium">ค่าบริการ</h5>
											</div>
										</div>

									</div>
								</template>
							</div>

							<div v-if="multiple_batches" class="d-flex pt-3 pr-4">
								<div class="batch-sidebar general px-2">
									<h3>ข้อมูลสรุป</h3>
								</div>
								<div class="w-100 form-row">
									<div class="col"></div>
									<div class="col-2 form-group text-right nowrap">
										<template v-if="is_general">
											<h2 class="text-primary">
												{{ submission_sample_count }}
											</h2>
											<h5 class="text-medium">ตัวอย่าง</h5>
										</template>
										<template v-else-if="is_disinfectant">
											<h2 class="text-primary">
												{{ submission.submission_batches.length }}
											</h2>
											<h5 class="text-medium">รายการยาฆ่าเชื้อ</h5>
										</template>
									</div>
									<div class="col-2 ml-5 form-group text-right">
										<h2 class="text-primary">
											{{ submission_test_count }}
										</h2>
										<h5 class="text-medium">รายการทดสอบ</h5>
									</div>
									<div class="col-1 form-group text-right nowrap">
										<h5 class="text-medium mt-2 ml-3">รวมเป็น</h5>
									</div>
									<div class="col-2 form-group text-right">
										<h2 class="text-primary">
											{{ to_display_price(submission_price) }}
										</h2>
										<h5 class="text-medium">ค่าบริการ</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			
			</div>
		</div>
		<div v-else class="w-100 pt-5 mt-5 text-center content-height-with-scroll">
			<LoadingAnimation color="primary" size="lg" />
		</div>
	</transition>
</div>
</template>

<script>
import { get_from_route } from '@/router'
import { ProcessSubmission, GroupTestMethods } from '@/util'
import { AUTH_DATA } from '@/graphql/local'
import { SUBMISSION_DETAIL } from '@/graphql/submission'
import { GENERAL_TEST_METHODS } from '@/graphql/tests'
import { SubmissionType } from '@/enum'

export default {
	name: 'view-submission',
	computed: {
		submission_key () {
			if (this.submission.BestLIMS_key) {
				return this.submission.BestLIMS_key
			}
			return this.submission.backend_key
		},
		is_general () {
			return this.submission.submission_type === SubmissionType.GENERAL
		},
		is_disinfectant () {
			return this.submission.submission_type === SubmissionType.DISINFECTANT
		},
		multiple_batches () {
			return this.submission.submission_batches.length > 1
		},
		submission_price () {
			return this.submission.submission_batches.reduce(
				(price, batch) => price += batch.price, 0
			)
		},
		submission_test_count () {
			return this.submission.submission_batches.reduce(
				(test_count, batch) => test_count += batch.test_count, 0
			)
		},
		submission_sample_count () {
			return this.submission.submission_batches.reduce(
				(sample_count, batch) => sample_count += batch.sample_count || 0, 0
			)
		},
	},
	methods: {
		on_back () {
			if (get_from_route() === 'edit-submission') {
				this.$router.push('submissions-list')
			}
			this.$router.go(-1)
		},
		download_all_reports () {

		},
	},
	apollo: {
		auth: {
			query: AUTH_DATA,
			update: data => data.auth
		},
		test_methods: {
			query: GENERAL_TEST_METHODS,
			update: data => GroupTestMethods(data.test_method_general),
		},
		submission: {
			query: SUBMISSION_DETAIL,
			variables () {
				return {
					key: this.$route.params.id
				}
			},
			update (data) {
				return ProcessSubmission(data.get_submission, this.test_methods)
			},
			skip () {
				return !this.test_methods
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.section {
	font-family: 'ThaiSans Neue';
	border-bottom: 1px solid $accent;
	padding: 1.5rem 0;
	&:first-child {
		padding-top: 0;
	}
	&:last-child {
		margin-bottom: 55vh;
	}
}
.btn.back-btn {
	@include media-breakpoint-up(xl) {
		position: absolute;
		top: 0;
		left: -100px;
	}
}
.batch-sidebar {
	&.general {
		min-width: 9rem;
		max-width: 9rem;
	}
	&.disinfectant {
		min-width: 15rem;
		max-width: 15rem;
	}
}

table {
	border-spacing: 0;
	border-collapse: separate;
	tr {
		td {
			border-bottom: 1px solid $accent;
		}
		&.highlight td {
			background: rgba($primary, .1);
			border-top: 2px solid $primary;
			border-bottom: 2px solid $primary;
			&:first-child {
				border-left: 2px solid $primary;
				border-top-left-radius: $border-radius-lg;
				border-bottom-left-radius: $border-radius-lg;
			}
			&:last-child {
				border-right: 2px solid $primary;
				border-top-right-radius: $border-radius-lg;
				border-bottom-right-radius: $border-radius-lg;
			}
		}
	}
}
</style>